const OfferList = () => {
  return (
    <>
      <section class="offer-list-page-section">
        <div class="container">
          <div class="main-contineer">
            <div class="Verified-Offers-cart">
              <h3 class="hading">
                <span>Crude Oil Verified Offers</span>{" "}
                <a href="javascript:void(0);" class="btn-theme primary ">
                  {" "}
                  Request a verified offer post
                </a>
              </h3>
              <div class="offer-box">
                <div class="Verified-Offers ">
                  <div class="table-responsive">
                    <table class="">
                      <thead>
                        <tr>
                          <th> Name </th>
                          <th>
                            <svg
                              width="20"
                              height="16"
                              viewBox="0 0 20 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.7379 2.2377C16.3319 1.84531 15.8499 1.53404 15.3193 1.32168C14.7888 1.10931 14.2201 1 13.6458 1C13.0716 1 12.5029 1.10931 11.9724 1.32168C11.4418 1.53404 10.9598 1.84531 10.5538 2.2377L9.71123 3.05166L8.86867 2.2377C8.04861 1.44548 6.93637 1.00041 5.77662 1.00041C4.61688 1.00041 3.50464 1.44548 2.68458 2.2377C1.86451 3.02993 1.40381 4.10441 1.40381 5.22479C1.40381 6.34516 1.86451 7.41965 2.68458 8.21187L3.52714 9.02583L9.71123 15L15.8953 9.02583L16.7379 8.21187C17.1441 7.81967 17.4663 7.354 17.6861 6.84146C17.9059 6.32893 18.0191 5.77958 18.0191 5.22479C18.0191 4.67 17.9059 4.12064 17.6861 3.60811C17.4663 3.09557 17.1441 2.6299 16.7379 2.2377Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </th>
                          <th>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.625 11.875C16.625 12.2949 16.4582 12.6977 16.1613 12.9946C15.8643 13.2915 15.4616 13.4583 15.0417 13.4583H5.54167L2.375 16.625V3.95833C2.375 3.53841 2.54181 3.13568 2.83875 2.83875C3.13568 2.54181 3.53841 2.375 3.95833 2.375H15.0417C15.4616 2.375 15.8643 2.54181 16.1613 2.83875C16.4582 3.13568 16.625 3.53841 16.625 3.95833V11.875Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </th>
                          <th>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_286_167)">
                                <path
                                  d="M0.791504 9.50033C0.791504 9.50033 3.95817 3.16699 9.49984 3.16699C15.0415 3.16699 18.2082 9.50033 18.2082 9.50033C18.2082 9.50033 15.0415 15.8337 9.49984 15.8337C3.95817 15.8337 0.791504 9.50033 0.791504 9.50033Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 11.875C10.8117 11.875 11.875 10.8117 11.875 9.5C11.875 8.18832 10.8117 7.125 9.5 7.125C8.18832 7.125 7.125 8.18832 7.125 9.5C7.125 10.8117 8.18832 11.875 9.5 11.875Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_286_167">
                                  <rect width="19" height="19" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </th>
                          <th>Type</th>
                          <th>Recent Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class=" black-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/41">
                              <div class="name-text-e">
                                <span class="n-name-1 color-a">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                              </div>
                            </a>
                          </td>
                          <td> 1 </td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-a">News</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 12:31</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/34">
                              <div class="name-text-e">
                                <span class="n-name-1 color-b">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                                <span class="disc-name"></span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-b">Sell</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 04:04</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/34">
                              <div class="name-text-e">
                                <sapn class="n-name-1 color-c">
                                  Welcome Your Request for a Verified Offer Post
                                </sapn>
                                <span class="disc-name"></span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-c">Finance</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 04:04</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/35">
                              <div class="name-text-e">
                                <span class="n-name-1 color-d">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-d">Buy</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-02 07:38</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="Verified-Offers-cart voc-padding">
              <h3 class="hading">
                <span>Crude Oil Offers</span>{" "}
                <a href="javascript:void(0);" class="btn-theme primary ">
                  {" "}
                  Create a new offer post
                </a>
              </h3>
              <div class="offer-box">
                <div class="Verified-Offers ">
                  <div class="table-responsive">
                    <table class="">
                      <thead class="colora">
                        <tr>
                          <th> Name </th>
                          <th>
                            <svg
                              width="20"
                              height="16"
                              viewBox="0 0 20 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.7379 2.2377C16.3319 1.84531 15.8499 1.53404 15.3193 1.32168C14.7888 1.10931 14.2201 1 13.6458 1C13.0716 1 12.5029 1.10931 11.9724 1.32168C11.4418 1.53404 10.9598 1.84531 10.5538 2.2377L9.71123 3.05166L8.86867 2.2377C8.04861 1.44548 6.93637 1.00041 5.77662 1.00041C4.61688 1.00041 3.50464 1.44548 2.68458 2.2377C1.86451 3.02993 1.40381 4.10441 1.40381 5.22479C1.40381 6.34516 1.86451 7.41965 2.68458 8.21187L3.52714 9.02583L9.71123 15L15.8953 9.02583L16.7379 8.21187C17.1441 7.81967 17.4663 7.354 17.6861 6.84146C17.9059 6.32893 18.0191 5.77958 18.0191 5.22479C18.0191 4.67 17.9059 4.12064 17.6861 3.60811C17.4663 3.09557 17.1441 2.6299 16.7379 2.2377Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </th>
                          <th>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.625 11.875C16.625 12.2949 16.4582 12.6977 16.1613 12.9946C15.8643 13.2915 15.4616 13.4583 15.0417 13.4583H5.54167L2.375 16.625V3.95833C2.375 3.53841 2.54181 3.13568 2.83875 2.83875C3.13568 2.54181 3.53841 2.375 3.95833 2.375H15.0417C15.4616 2.375 15.8643 2.54181 16.1613 2.83875C16.4582 3.13568 16.625 3.53841 16.625 3.95833V11.875Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </th>
                          <th>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_286_167)">
                                <path
                                  d="M0.791504 9.50033C0.791504 9.50033 3.95817 3.16699 9.49984 3.16699C15.0415 3.16699 18.2082 9.50033 18.2082 9.50033C18.2082 9.50033 15.0415 15.8337 9.49984 15.8337C3.95817 15.8337 0.791504 9.50033 0.791504 9.50033Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 11.875C10.8117 11.875 11.875 10.8117 11.875 9.5C11.875 8.18832 10.8117 7.125 9.5 7.125C8.18832 7.125 7.125 8.18832 7.125 9.5C7.125 10.8117 8.18832 11.875 9.5 11.875Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_286_167">
                                  <rect width="19" height="19" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </th>
                          <th>Type</th>
                          <th>Recent Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class=" black-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/41">
                              <div class="name-text-e">
                                <span class="n-name-1 color-a">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                              </div>
                            </a>
                          </td>
                          <td> 1 </td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-a">News</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 12:31</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/34">
                              <div class="name-text-e">
                                <span class="n-name-1 color-b">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                                <span class="disc-name"></span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-b">Sell</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 04:04</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/34">
                              <div class="name-text-e">
                                <sapn class="n-name-1 color-c">
                                  Welcome Your Request for a Verified Offer Post
                                </sapn>
                                <span class="disc-name"></span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-c">Finance</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 04:04</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/35">
                              <div class="name-text-e">
                                <span class="n-name-1 color-d">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-d">Buy</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-02 07:38</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default OfferList;
