import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import wLogo from "../../assets/img/w-logo.svg";

const AboutComponent = () => {
  const navigate = useNavigate();

  const navigateTo = (e, page) => {
    navigate(`/${page}`);
    e.preventDefault();
  };

  return (
    <>
      <section class="g-top-header">
        <div class="container">
          <div class="list">
            <ul>
              <li>
                <a onClick={(e) => navigateTo(e, "login")}>Login / Register</a>
              </li>
              <li>
                <a href="javascript:void(0);">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <header class="main-header">
        <div class="container">
          <div class="headerRow">
            <div class="logoImage">
              <a href="javascript:void(0);">
                <img src={logo} alt="" />
              </a>
            </div>
            <div class="menusrightbox">
              <div class="mobileOvely menuAction"></div>
              <div class="menuBar">
                <div class="closebtn menuAction">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
                <div class="mobile-v main-logo">
                  <a href="javascript:void(0);">
                    <img src={wLogo} alt="" />
                  </a>
                  <a href="javascript:void(0);">LOG IN / REGISTER</a>
                </div>
                <ul class="nav me-menu">
                  <li>
                    <a onClick={(e) => navigateTo(e, "index")}>Home</a>
                  </li>
                  <li>
                    <a onClick={(e) => navigateTo(e, "about")}>About Us</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="has-arrow">
                      <span> petroleum</span>
                    </a>
                    <ul class="me-sub-menu">
                      <li>
                        <a href="javascript:void(0);">
                          <span>Crude Oil</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>EN590</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>LCO</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>LNG / LPG</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>A1 / JP54</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Others P</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="has-arrow">
                      <span> metal</span>
                    </a>
                    <ul class="me-sub-menu">
                      <li>
                        <a href="javascript:void(0);">
                          <span>Copper</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Aluminum</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Gold</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Used Rail</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Iron Ore</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Others M</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="has-arrow">
                      <span> Agri/Meat</span>
                    </a>
                    <ul class="me-sub-menu">
                      <li>
                        <a href="javascript:void(0);">
                          <span>Soybean</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Sugar</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Seed Oils</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Beef</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Chicken</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Pork</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Lamb</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Others AM</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="has-arrow">
                      <span> Finance</span>
                    </a>
                    <ul class="me-sub-menu">
                      <li>
                        <a href="javascript:void(0);">
                          <span>Project Finance</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Bank Instrument</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Bit Coin</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Others F</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="has-arrow">
                      <span> Service</span>
                    </a>
                    <ul class="me-sub-menu">
                      <li>
                        <a href="javascript:void(0);">
                          <span>Escrow Lawyer</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Inspection</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Storage</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Banking</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Others S</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="has-arrow">
                      <span> Other</span>
                    </a>
                    <ul class="me-sub-menu">
                      <li>
                        <a href="javascript:void(0);">
                          <span>Property / Art / Rare Items</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Others O</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="has-arrow">
                      <span>Inquiry</span>
                    </a>
                    <ul class="me-sub-menu">
                      <li>
                        <a href="javascript:void(0);">
                          <span>Verified Offers</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Website Issues</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Partner Program</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <span>Others</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="mobile-v">
                    <a href="javascript:void(0);">NEWS</a>
                  </li>
                  <li class="mobile-v">
                    <a href="javascript:void(0);">DOCUMENTS</a>
                    <div class="doc-in">
                      <span>
                        <a href="javascript:void(0);">Doc1</a>
                      </span>
                      <span>
                        <a href="javascript:void(0);">Doc2</a>
                      </span>
                      <span>
                        <a href="javascript:void(0);">Doc3</a>
                      </span>
                      <span>
                        <a href="javascript:void(0);">Doc4</a>
                      </span>
                      <span>
                        <a href="javascript:void(0);">Doc5</a>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mobile-menu menuAction">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section class="g-last-header">
        <div class="container">
          <div class="list">
            <ul>
              <li>
                <a href="javascript:void(0);">RealCommodityTrading</a>
              </li>
              <li>
                <a href="javascript:void(0);">RealPetroTrading</a>
              </li>
              <li>
                <a href="javascript:void(0);">RealMetalTrading</a>
              </li>
              <li>
                <a href="javascript:void(0);">RealAgriTrading</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutComponent;
