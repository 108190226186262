import { useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();

  const navigateTo = (e, page) => {
    navigate(`/${page}`);
    e.preventDefault();
  };

  return (
    <>
      <section class="login-section-3">
        <div class="forms signupbox">
          {/* <!-- <h1 class="text-center main-hadding">Get Started for Free in 1 minute.</h1> --> */}
          <div class="formsignupch">
            <div class="left-set">
              <form action="index.php">
                <h3 class="top-name text-center">User Registration</h3>
                <div class="main-box-sign">
                  <div class="input-box acol-6">
                    <label for="">
                      Email Address<span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      autocomplete="off"
                      id="email"
                      idd="fixss1"
                      name="email"
                      class="dexktop-control"
                      placeholder="Please enter your email name as the login name"
                    />
                  </div>
                  <div class="input-box acol-6"></div>

                  <div class="input-box acol-6">
                    <label for="">
                      Login Password<span class="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      class="dexktop-control"
                      id="passwordl"
                      idd="fixss2"
                      name="choose_password"
                      placeholder="Min 6 characters"
                    />
                    <span
                      toggle="#passwordl"
                      class="fa fa-fw field-icon toggle-password fa-eye"
                    ></span>
                  </div>
                  <div class="input-box acol-6">
                    <label for="">
                      Confirm Password<span class="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      class="dexktop-control"
                      id="passwordb"
                      idd="fixss2"
                      name="choose_password"
                      placeholder="Confirm Password"
                    />
                    <span
                      toggle="#passwordb"
                      class="fa fa-fw field-icon toggle-password fa-eye"
                    ></span>
                  </div>

                  <div class="input-box acol-6">
                    <label for="">
                      First Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="f-name"
                      idd="fixss3"
                      name="first_name"
                      class="dexktop-control"
                      placeholder="First name"
                    />
                  </div>
                  <div class="input-box acol-6">
                    <label for="">
                      Last name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      idd="fixss4"
                      name="last_name"
                      class="dexktop-control"
                      placeholder="Last name"
                    />
                  </div>
                  <div class="check-boxes mb-3 acol-12">
                    <p>
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          class="checkbox-in"
                          name="i_agree"
                          id="agree"
                        />
                        <label for="agree" class="checkbox-label">
                          While creating a website account: I agree to abide by
                          the realcommoditytrading.com{" "}
                          <a href="javascript:void(0);">Member Agreement</a> -
                          Willing to receive emails from{" "}
                          <a href="javascript:void(0);">
                            realcommoditytrading.com
                          </a>
                        </label>
                      </span>
                    </p>
                  </div>
                </div>
                <button
                  class="btn-theme btn-secondary full-width"
                  type="button"
                >
                  Agree and Register
                </button>
                <div class="other-links">
                  {/* <!-- <span class="orbox"><span>OR</span> </span>
                                <div class="sociallinks">
                                    <ul>
                                        <li><a href="javascript:void(0);"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        <li><a href="javascript:void(0);"><i class="fa fa-google" aria-hidden="true"></i></a></li>
                                        <li><a href="javascript:void(0);"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div> --> */}
                  <p class="text-center mt-3  text-dfo">
                    Already have an account?{" "}
                    <a onClick={(e) => navigateTo(e, "login")}> Log in </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Signup;
