const About = () => {
  return (
    <>
      <section>
        <div class="top-banner">
          <div class="video-b">
            <video autoPlay muted loop playsInline preload="metadata">
              <source
                src="https://realcommoditytrading.com/frontend/images/vedio-bg.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div class="container">
            <div class="contane">
              <h1>ABOUT US</h1>
              <p>
                Global Intelligence Network of Commodity Trading Businesses.
                Create the Better World with Our Good Partners. Together, We are
                Stronger.
              </p>
              <a href="javascript:void(0);" class="btn-theme primary">
                JOIN US AS A PARTNER
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="mainboxcontent">
          <div class="container">
            <div class="boxcontent">
              <div class="contantleft">
                <h1>Who we are</h1>
                <p>
                  Good Partners World Co., Ltd. works with qualified partners
                  around the world from more than 30 countries, including USA,
                  Russia, China, Brazil, Iran, UAE, Saudi Arabia, Germany,
                  England, Korea, Japan, Taiwan, Philippines, India, Bangladesh,
                  and Singapore. Each of our team members has a minimum of 10
                  years’ experience working with commodity trading businesses.
                </p>
                <a
                  href="javascript:void(0);"
                  class="btn-theme btn-secondary mt-3"
                >
                  Request a Verified Offer Post
                </a>
              </div>
              <div class="contantright">
                <div class="image">
                  <img src="assets/img/about-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainboxcontent">
          <div class="container">
            <div class="boxcontent ">
              <div class="contantleft color-b">
                <h1>How we help our members</h1>
                <p>
                  We help our members source verified sellers and buyers through
                  our vast network of professional business partners. Our strong
                  nexus dramatically reduces our members’ risks such as those in
                  commodity trading transactions requiring large investments. We
                  also provide various solutions for our members’ businesses.
                  Please check our website to find more for your business needs.
                </p>
                <a href="javascript:void(0);" class="btn-theme secondary mt-3">
                  Request a Verified Offer Post
                </a>
              </div>
              <div class="contantright">
                <div class="image">
                  <img src="assets/img/about-2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mainboxcontent pb-5">
          <div class="container">
            <div class="boxcontent">
              <div class="fullcontent">
                <h1>How it works</h1>
                <p>
                  We believe time and risk management are crucial factors in
                  taking on transactions in the commodity trading market.
                  Through our network of partners, we help both our sellers and
                  buyers identify and differentiate between reputable and
                  unsubstantial offers thereby saving time and reducing risk. We
                  confirm seller’s credit by inspecting the previous records
                  and/or POPs within last couple of months provided from the
                  sellers. We confirm offers from buyers through an examination
                  of the buyers’ Proof of Funds issued within last couple of
                  months of an offer date. When our verification processes are
                  completed, offers are listed in our VERIFIED OFFERS section.
                  The other party, who replied to the verified offer(s), must
                  provide us their Previous Record(s) or POF before the
                  communication is started. In this way, we don’t waste any
                  valuable time to dealing with fake seller(s) or buyer(s){" "}
                  <u>Get Started Now</u>
                </p>
                <a href="javascript:void(0);" class="btn-theme primary mt-3">
                  Request a Verified Offer Post
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mainboxcontent color-w">
          <div class="container">
            <div class="boxcontent">
              <div class="fullcontent">
                <p class="pb-4">
                  For{" "}
                  <i>
                    <b>Verified Offers</b>
                  </i>
                  , we help complete transactions amongst our major players:{" "}
                  <br /> the Seller, the Buyer, the Intermediaries (if
                  involved), and the Financiers (if necessary).
                </p>
                <p>
                  **{" "}
                  <small class="">
                    {" "}
                    Above documents provided from the seller and buyer are
                    encrypted and securely stored in offline only. We will not
                    publish above documents for any reason without provider’s
                    written consent. It will be destroyed immediately upon the
                    document provider’s request.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;