import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import HeaderComponent from "./components/header/header";
import FooterComponent from "./components/footer/footer";
import AboutComponent from "./components/about/about";
import InquiryOthersComponent from "./components/InquiryOthers/InquiryOthers";
import InquiryPartnerProgramComponent from "./components/InquiryPartnerProgram/InquiryPartnerProgram";
import InquiryVerifiedOfferComponent from "./components/InquiryVerifiedOffer/InquiryVerifiedOffer";
import InquiryWebsiteIssueComponent from "./components/InquiryWebsiteIssue/InquiryWebsiteIssue";
import MyPostingComponent from "./components/MyPosting/MyPosting";
import OfferListComponent from "./components/OfferList/OfferList";
import RequestVerifiedOfferPostCreationComponent from "./components/RequestVerifiedOfferPostCreation/RequestVerifiedOfferPostCreation";
import LoginComponent from "./components/login/login";
import SignupComponent from "./components/signup/signup";
import ForgotPasswordComponent from "./components/forgotpassword/forgotpassword";
import HomeComponent from "./components/home/home";

function App() {
    return ( <
        >
        <
        HeaderComponent / >
        <
        Routes >
        <
        Route path = "/" >
        <
        Route index element = { < HomeComponent / > }
        /> <
        Route path = "/about"
        element = { < AboutComponent / > }
        /> <
        Route path = "/Inquiry-Others-Page"
        element = { < InquiryOthersComponent / > }
        /> <
        Route path = "/Inquiry-Partner-Program-Page"
        element = { < InquiryPartnerProgramComponent / > }
        /> <
        Route path = "/Inquiry-Verified-Offer-Page"
        element = { < InquiryVerifiedOfferComponent / > }
        /> <
        Route path = "/Inquiry-Website-Issue-Page"
        element = { < InquiryWebsiteIssueComponent / > }
        /> <
        Route path = "/my-posting-page"
        element = { < MyPostingComponent / > }
        /> <
        Route path = "/offer-list-page"
        element = { < OfferListComponent / > }
        /> <
        Route path = "/Request-verified-offer-post-creating-an-offer-post"
        element = { < RequestVerifiedOfferPostCreationComponent / > }
        /> <
        Route path = "/login"
        element = { < LoginComponent / > }
        /> <
        Route path = "/signup"
        element = { < SignupComponent / > }
        /> <
        Route path = "/forgot-password"
        element = { < ForgotPasswordComponent / > }
        /> <
        /Route> <
        /Routes> <
        FooterComponent / >
        <
        />
    );
}

export default App;