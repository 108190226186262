import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const navigateTo = (e, page) => {
    navigate(`/${page}`);
    e.preventDefault();
  };

  return (
    <>
      <section class="login-section-3">
        <div class="forms login">
          <form action="index.php">
            <h3 class="text-center top-name">Log in </h3>
            <p class="text-center mt-2 pb-4 text-dfo">
              Don't have an account?{" "}
              <a onClick={(e) => navigateTo(e, "signup")}> Register</a>
            </p>

            <div class="input-box">
              <input
                type="email"
                autocomplete="off"
                id="email"
                idd="fixss1"
                placeholder=" Email"
                name="email"
                class="dexktop-control"
              />
            </div>
            <div class="input-box">
              <input
                type="password"
                class="dexktop-control"
                id="passwordl2"
                idd="fixss2"
                name="password"
                placeholder=" Password"
              />
              <span
                toggle="#passwordl2"
                class="fa fa-fw field-icon toggle-password fa-eye"
              ></span>
            </div>
            <p class="mb-3 pt-1">
              <span class="checkbox">
                <input
                  type="checkbox"
                  class="checkbox-in"
                  name="i_agree"
                  id="agree"
                />
                <label for="agree" class="checkbox-label">
                  Remember me
                </label>
              </span>
            </p>
            <button class="btn-theme btn-secondary full-width" type="button">
              Sign In
            </button>
            <p class="mt-3 text-center text-dfo forgot">
              {" "}
              <a onClick={(e) => navigateTo(e, "forgot-password")}>Forgot password?</a>
            </p>

            <div class="other-links">
              {/* <span class="orbox"><span>OR</span> </span> */}
              {/* <div class="sociallinks">
                            <ul>
                                <li><a href="javascript:void(0);"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                <li><a href="javascript:void(0);"><i class="fa fa-google" aria-hidden="true"></i></a></li>
                                <li><a href="javascript:void(0);"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                            </ul>
                        </div> */}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
export default Login;
