const home = () => {
  return (
    <>
      <section class="main-benner">
        <div class="min-continet">
          <div class="container">
            <div class="contwnt-body">
              <h1>
                Global Intelligence Network of Commodity Trading Businesses
              </h1>
              <p>
                We help sourcing qualified RWA sellers and buyers through our
                network of partners for the company who requests us with
                Previous Record(s), POP(s), or POF(s).
              </p>
              <a
                href="javascript:void(0);"
                class="btn-theme pr-5 pl-5 primary mt-3"
              >
                JOIN US AS A PARTNER
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="main-contineer">
            <div class="Verified-Offers-cart">
              <div class="offer-box">
                <div class="left-bar">
                  <div class="nev-box">
                    <div class="box">
                      <h3>
                        Verified <br /> Offers{" "}
                      </h3>
                      <p>
                        Verified offers are created by our company only if
                        Previous Record(s), Proof of Product(s), or Proof of
                        Fund(s) is provided to us.{" "}
                        <a href="javascript:void(0);" class="colora">
                          More{" "}
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </a>
                      </p>
                      <div class="pt-2">
                        {/* <!--<a href="javascript:void(0);" class="text-white">goto Verified Offers posting page (WE NEED TO THINK)  <i class="fa fa-angle-right" aria-hidden="true"></i></a>--> */}
                        <a
                          href="javascript:void(0);"
                          class="btn-theme full-width primary mt-3"
                        >
                          Request a Verified Offer Post
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Verified-Offers ">
                  <div class="table-responsive">
                    <table class="">
                      <thead>
                        <tr>
                          <th> Subject </th>
                          <th>
                            <svg
                              width="20"
                              height="16"
                              viewBox="0 0 20 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.7379 2.2377C16.3319 1.84531 15.8499 1.53404 15.3193 1.32168C14.7888 1.10931 14.2201 1 13.6458 1C13.0716 1 12.5029 1.10931 11.9724 1.32168C11.4418 1.53404 10.9598 1.84531 10.5538 2.2377L9.71123 3.05166L8.86867 2.2377C8.04861 1.44548 6.93637 1.00041 5.77662 1.00041C4.61688 1.00041 3.50464 1.44548 2.68458 2.2377C1.86451 3.02993 1.40381 4.10441 1.40381 5.22479C1.40381 6.34516 1.86451 7.41965 2.68458 8.21187L3.52714 9.02583L9.71123 15L15.8953 9.02583L16.7379 8.21187C17.1441 7.81967 17.4663 7.354 17.6861 6.84146C17.9059 6.32893 18.0191 5.77958 18.0191 5.22479C18.0191 4.67 17.9059 4.12064 17.6861 3.60811C17.4663 3.09557 17.1441 2.6299 16.7379 2.2377Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </th>
                          <th>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.625 11.875C16.625 12.2949 16.4582 12.6977 16.1613 12.9946C15.8643 13.2915 15.4616 13.4583 15.0417 13.4583H5.54167L2.375 16.625V3.95833C2.375 3.53841 2.54181 3.13568 2.83875 2.83875C3.13568 2.54181 3.53841 2.375 3.95833 2.375H15.0417C15.4616 2.375 15.8643 2.54181 16.1613 2.83875C16.4582 3.13568 16.625 3.53841 16.625 3.95833V11.875Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </th>
                          <th>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_286_167)">
                                <path
                                  d="M0.791504 9.50033C0.791504 9.50033 3.95817 3.16699 9.49984 3.16699C15.0415 3.16699 18.2082 9.50033 18.2082 9.50033C18.2082 9.50033 15.0415 15.8337 9.49984 15.8337C3.95817 15.8337 0.791504 9.50033 0.791504 9.50033Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 11.875C10.8117 11.875 11.875 10.8117 11.875 9.5C11.875 8.18832 10.8117 7.125 9.5 7.125C8.18832 7.125 7.125 8.18832 7.125 9.5C7.125 10.8117 8.18832 11.875 9.5 11.875Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_286_167">
                                  <rect width="19" height="19" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </th>
                          <th>Type</th>
                          <th>Recent Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class=" black-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/41">
                              <div class="name-text-e">
                                <span class="n-name-1 color-a">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                              </div>
                            </a>
                          </td>
                          <td> 1 </td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-a">News</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 12:31</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/34">
                              <div class="name-text-e">
                                <span class="n-name-1 color-b">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                                <span class="disc-name"></span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-b">Sell</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 04:04</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/34">
                              <div class="name-text-e">
                                <sapn class="n-name-1 color-c">
                                  Welcome Your Request for a Verified Offer Post
                                </sapn>
                                <span class="disc-name"></span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-c">Finance</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 04:04</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/35">
                              <div class="name-text-e">
                                <span class="n-name-1 color-d">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-d">Buy</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-02 07:38</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="main-contineer">
            <div class="Verified-Offers-cart">
              <div class="offer-box">
                <div class="left-bar">
                  <div class="nev-box">
                    <div class="box">
                      <h3 class="colora">Offers </h3>
                      <p>
                        Offers are created by our members but without Previous
                        Record(s), Proof of Product(s), or Proof of Fund(s).{" "}
                        <a href="javascript:void(0);" class="colorb">
                          More{" "}
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </a>
                      </p>
                      <div class="pt-2">
                        <a
                          href="javascript:void(0);"
                          class="btn-theme full-width primary mt-3"
                        >
                          Create an Offer Post
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Verified-Offers ">
                  <div class="table-responsive">
                    <table class="">
                      <thead class="colora">
                        <tr>
                          <th> Subject </th>
                          <th>
                            <svg
                              width="20"
                              height="16"
                              viewBox="0 0 20 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.7379 2.2377C16.3319 1.84531 15.8499 1.53404 15.3193 1.32168C14.7888 1.10931 14.2201 1 13.6458 1C13.0716 1 12.5029 1.10931 11.9724 1.32168C11.4418 1.53404 10.9598 1.84531 10.5538 2.2377L9.71123 3.05166L8.86867 2.2377C8.04861 1.44548 6.93637 1.00041 5.77662 1.00041C4.61688 1.00041 3.50464 1.44548 2.68458 2.2377C1.86451 3.02993 1.40381 4.10441 1.40381 5.22479C1.40381 6.34516 1.86451 7.41965 2.68458 8.21187L3.52714 9.02583L9.71123 15L15.8953 9.02583L16.7379 8.21187C17.1441 7.81967 17.4663 7.354 17.6861 6.84146C17.9059 6.32893 18.0191 5.77958 18.0191 5.22479C18.0191 4.67 17.9059 4.12064 17.6861 3.60811C17.4663 3.09557 17.1441 2.6299 16.7379 2.2377Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </th>
                          <th>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.625 11.875C16.625 12.2949 16.4582 12.6977 16.1613 12.9946C15.8643 13.2915 15.4616 13.4583 15.0417 13.4583H5.54167L2.375 16.625V3.95833C2.375 3.53841 2.54181 3.13568 2.83875 2.83875C3.13568 2.54181 3.53841 2.375 3.95833 2.375H15.0417C15.4616 2.375 15.8643 2.54181 16.1613 2.83875C16.4582 3.13568 16.625 3.53841 16.625 3.95833V11.875Z"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </th>
                          <th>
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_286_167)">
                                <path
                                  d="M0.791504 9.50033C0.791504 9.50033 3.95817 3.16699 9.49984 3.16699C15.0415 3.16699 18.2082 9.50033 18.2082 9.50033C18.2082 9.50033 15.0415 15.8337 9.49984 15.8337C3.95817 15.8337 0.791504 9.50033 0.791504 9.50033Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 11.875C10.8117 11.875 11.875 10.8117 11.875 9.5C11.875 8.18832 10.8117 7.125 9.5 7.125C8.18832 7.125 7.125 8.18832 7.125 9.5C7.125 10.8117 8.18832 11.875 9.5 11.875Z"
                                  stroke="white"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_286_167">
                                  <rect width="19" height="19" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </th>
                          <th>Type</th>
                          <th>Recent Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class=" black-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/41">
                              <div class="name-text-e">
                                <span class="n-name-1 color-a">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                              </div>
                            </a>
                          </td>
                          <td> 1 </td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-a">News</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 12:31</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/34">
                              <div class="name-text-e">
                                <span class="n-name-1 color-b">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                                <span class="disc-name"></span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-b">Sell</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 04:04</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/34">
                              <div class="name-text-e">
                                <sapn class="n-name-1 color-c">
                                  Welcome Your Request for a Verified Offer Post
                                </sapn>
                                <span class="disc-name"></span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-c">Finance</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-03 04:04</span>
                            </a>
                          </td>
                        </tr>

                        <tr class=" buy-color">
                          <td>
                            <a href="https://realcommoditytrading.com/v-offer-detail/35">
                              <div class="name-text-e">
                                <span class="n-name-1 color-d">
                                  Welcome Your Request for a Verified Offer Post
                                </span>
                              </div>
                            </a>
                          </td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td class="color-d">Buy</td>
                          <td>
                            <a href="javascript:void(0);">
                              <span>2022-11-02 07:38</span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>

      <section class="mainboxcontent homeabout">
        <div class="container">
          <div class="boxcontent">
            <div class="contantright">
              <div class="vedio">
                <video controls="">
                  <source
                    src="https://realcommoditytrading.com/frontend/images/vedio-bg.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div class="contantleft">
              <h1>ABOUT US</h1>
              <p>
                Global Intelligence Network of Commodity Trading Businesses.
                Create the Better World with Our Good Partners. Together, We are
                Stronger.
              </p>
              <a href="about.php" class="btn-theme secondary px-5 mt-3">
                Find More
              </a>
            </div>
          </div>
          <hr />
        </div>
      </section>
      <section>
        <div class="container">
          <div class="faqsection">
            <div class="fawRow">
              <div class="faqright">
                <ul>
                  <li class="">
                    <h5 class="Faqaction">
                      <a href="javascript:void(0);">
                        How to create an offer post?{" "}
                      </a>
                    </h5>
                    <div class="faqcontent">
                      <p>
                        To create an offer post you first need to be logged in
                        and registered, from the menu select the item you want
                        to make an offer post for, Click on "create new offer
                        post", provide the details regarding the offer, and post
                        it. After posting, the offer can be modified only by the
                        offer creator.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5 class="Faqaction">
                      <a href="javascript:void(0);" class="">
                        How to request a verified offer post?
                      </a>
                    </h5>
                    <div class="faqcontent">
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Et soluta quisquam incidunt quidem tempora nam,
                        ipsum perspiciatis ex corporis cumque sed, hic optio
                        dolores rerum in consequatur a sapiente libero.
                        Laudantium nostrum consectetur ratione, ipsam molestias
                        necessitatibus voluptatum esse molestiae, dignissimos
                        maxime veniam aliquam voluptate distinctio dolores quod
                        perspiciatis quaerat, culpa incidunt itaque consequuntur
                        quas. Debitis rerum non iure corrupti? Ducimus quidem
                        dignissimos unde, nostrum voluptates iusto aliquam earum
                        eos culpa eius temporibus veritatis quo illo aspernatur
                        nemo. Aliquid facilis, possimus quisquam laborum libero
                        soluta? Nihil, provident. Accusantium, expedita magnam.
                        Exercitationem sint sequi minus quasi optio molestias,
                        repudiandae vel iste voluptates aliquid quis mollitia
                        asperiores? Delectus ab quae corrupti amet cupiditate
                        quos reiciendis tempora, aliquid iure expedita possimus
                        exercitationem necessitatibus.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5 class="Faqaction">
                      <a href="javascript:void(0);" class="">
                        What happens to the documents that I share?
                      </a>
                    </h5>
                    <div class="faqcontent">
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Et soluta quisquam incidunt quidem tempora nam,
                        ipsum perspiciatis ex corporis cumque sed, hic optio
                        dolores rerum in consequatur a sapiente libero.
                        Laudantium nostrum consectetur ratione, ipsam molestias
                        necessitatibus voluptatum esse molestiae, dignissimos
                        maxime veniam aliquam voluptate distinctio dolores quod
                        perspiciatis quaerat, culpa incidunt itaque consequuntur
                        quas. Debitis rerum non iure corrupti? Ducimus quidem
                        dignissimos unde, nostrum voluptates iusto aliquam earum
                        eos culpa eius temporibus veritatis quo illo aspernatur
                        nemo. Aliquid facilis, possimus quisquam laborum libero
                        soluta? Nihil, provident. Accusantium, expedita magnam.
                        Exercitationem sint sequi minus quasi optio molestias,
                        repudiandae vel iste voluptates aliquid quis mollitia
                        asperiores? Delectus ab quae corrupti amet cupiditate
                        quos reiciendis tempora, aliquid iure expedita possimus
                        exercitationem necessitatibus.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5 class="Faqaction">
                      <a href="javascript:void(0);" class="">
                        What is the Partner program?
                      </a>
                    </h5>
                    <div class="faqcontent">
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Et soluta quisquam incidunt quidem tempora nam,
                        ipsum perspiciatis ex corporis cumque sed, hic optio
                        dolores rerum in consequatur a sapiente libero.
                        Laudantium nostrum consectetur ratione, ipsam molestias
                        necessitatibus voluptatum esse molestiae, dignissimos
                        maxime veniam aliquam voluptate distinctio dolores quod
                        perspiciatis quaerat, culpa incidunt itaque consequuntur
                        quas. Debitis rerum non iure corrupti? Ducimus quidem
                        dignissimos unde, nostrum voluptates iusto aliquam earum
                        eos culpa eius temporibus veritatis quo illo aspernatur
                        nemo. Aliquid facilis, possimus quisquam laborum libero
                        soluta? Nihil, provident. Accusantium, expedita magnam.
                        Exercitationem sint sequi minus quasi optio molestias,
                        repudiandae vel iste voluptates aliquid quis mollitia
                        asperiores? Delectus ab quae corrupti amet cupiditate
                        quos reiciendis tempora, aliquid iure expedita possimus
                        exercitationem necessitatibus.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5 class="Faqaction">
                      <a href="javascript:void(0);" class="">
                        What is the benefit of becoming a partner?
                      </a>
                    </h5>
                    <div class="faqcontent">
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Et soluta quisquam incidunt quidem tempora nam,
                        ipsum perspiciatis ex corporis cumque sed, hic optio
                        dolores rerum in consequatur a sapiente libero.
                        Laudantium nostrum consectetur ratione, ipsam molestias
                        necessitatibus voluptatum esse molestiae, dignissimos
                        maxime veniam aliquam voluptate distinctio dolores quod
                        perspiciatis quaerat, culpa incidunt itaque consequuntur
                        quas. Debitis rerum non iure corrupti? Ducimus quidem
                        dignissimos unde, nostrum voluptates iusto aliquam earum
                        eos culpa eius temporibus veritatis quo illo aspernatur
                        nemo. Aliquid facilis, possimus quisquam laborum libero
                        soluta? Nihil, provident. Accusantium, expedita magnam.
                        Exercitationem sint sequi minus quasi optio molestias,
                        repudiandae vel iste voluptates aliquid quis mollitia
                        asperiores? Delectus ab quae corrupti amet cupiditate
                        quos reiciendis tempora, aliquid iure expedita possimus
                        exercitationem necessitatibus.
                      </p>
                    </div>
                  </li>
                  <li>
                    <h5 class="Faqaction">
                      <a href="javascript:void(0);" class="">
                        How to use language conversion on our website using MS
                        Edge or Google Chrome?
                      </a>
                    </h5>
                    <div class="faqcontent">
                      <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Et soluta quisquam incidunt quidem tempora nam,
                        ipsum perspiciatis ex corporis cumque sed, hic optio
                        dolores rerum in consequatur a sapiente libero.
                        Laudantium nostrum consectetur ratione, ipsam molestias
                        necessitatibus voluptatum esse molestiae, dignissimos
                        maxime veniam aliquam voluptate distinctio dolores quod
                        perspiciatis quaerat, culpa incidunt itaque consequuntur
                        quas. Debitis rerum non iure corrupti? Ducimus quidem
                        dignissimos unde, nostrum voluptates iusto aliquam earum
                        eos culpa eius temporibus veritatis quo illo aspernatur
                        nemo. Aliquid facilis, possimus quisquam laborum libero
                        soluta? Nihil, provident. Accusantium, expedita magnam.
                        Exercitationem sint sequi minus quasi optio molestias,
                        repudiandae vel iste voluptates aliquid quis mollitia
                        asperiores? Delectus ab quae corrupti amet cupiditate
                        quos reiciendis tempora, aliquid iure expedita possimus
                        exercitationem necessitatibus.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="faqLeft">
                <div class="image">
                  <img src="assets/img/faq.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default home;
