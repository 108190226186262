const RequestVerifiedOfferPostCreation = () => {
  return (
    <>
      <section class="login-section-3">
        <div class="container">
          <div class="forms rvop-form" data-form="Buy">
            <form action="">
              <h3 class="top-name text-center">
                Request for a Verified Offer Post
              </h3>
              <p class="text-foa">
                We must receive Previous Record(s) with POP from the seller and
                POF from the buyer.
              </p>
              <div class="row">
                <div class="col-12">
                  <div class="check-boxes mb-3 acol-12">
                    <p class="mb-2">
                      <b>
                        Offer Status Selection<span class="text-danger">*</span>{" "}
                      </b>
                    </p>
                    <div class="Cby-checkbox ab-check">
                      <span class="checkbox color-a">
                        <input
                          type="radio"
                          class="checkbox-in Buy"
                          name="bsf"
                          id="Buy"
                          checked
                        />
                        <label for="Buy" class="checkbox-label">
                          Buy
                        </label>
                      </span>
                      <span class="checkbox color-b">
                        <input
                          type="radio"
                          class="checkbox-in Sell"
                          name="bsf"
                          id="Sell"
                        />
                        <label for="Sell" class="checkbox-label">
                          Sell
                        </label>
                      </span>
                      <span class="checkbox color-c">
                        <input
                          type="radio"
                          class="checkbox-in Finance"
                          name="bsf"
                          id="Finance"
                        />
                        <label for="Finance" class="checkbox-label">
                          Finance
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Product">
                      Product<span class="text-danger">*</span>
                    </label>
                    <input
                      type="test"
                      autocomplete="off"
                      id="Product"
                      idd="fixss1"
                      name=""
                      class="dexktop-control"
                      placeholder="Please describe your offer product"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Origin">
                      Origin<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="dexktop-control"
                      id="Origin"
                      name=""
                      placeholder="Origin"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Quantity">
                      Type/Grade/Specification<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="dexktop-control"
                      id="Quantity"
                      name=""
                      placeholder="Type/Grade/Specification"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="">
                      Quantity<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="f-name"
                      name=""
                      class="dexktop-control"
                      placeholder="EX: Min 30,000MT, Max 2Mbbls"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">
                      Contract<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="EX: SPOT, Annual, Trial + Annual"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">
                      Discharging Port(s)<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="Port name(s)"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">City</label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="City"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">
                      Discharging Country<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="Country(s)"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">
                      Loading Port(s) <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="Port name(s)"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">City</label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="City"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">
                      Loading Country<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="Country(s)"
                    />
                  </div>
                </div>
                <div class="col-lg-6  col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">
                      Payments<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="EX: MT760 at sight, Transferable MT 700, Revoling DLC"
                    />
                  </div>
                </div>
                <div class="col-lg-6  col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">
                      Performance Bond<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="l-name"
                      name=""
                      class="dexktop-control"
                      placeholder="EX: 2%"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-lg-6 col-12">
                      <div class="check-boxes  cb-box mb-3 acol-6">
                        <p class="mb-2">
                          <b>Inspection </b>
                        </p>
                        <div class="Cby-checkbox">
                          <span class="checkbox">
                            <input
                              type="radio"
                              class="checkbox-in "
                              name="sco"
                              id="SGS"
                              checked
                            />
                            <label for="SGS" class="checkbox-label">
                              SGS
                            </label>
                          </span>
                          <span class="checkbox">
                            <input
                              type="radio"
                              class="checkbox-in "
                              name="sco"
                              id="CIQ"
                            />
                            <label for="CIQ" class="checkbox-label">
                              CIQ
                            </label>
                          </span>
                          <span class="checkbox">
                            <input
                              type="radio"
                              class="checkbox-in"
                              name="sco"
                              id="Other"
                            />
                            <label for="Other" class="checkbox-label">
                              Other
                            </label>
                          </span>
                          <span class="checkbox input-box">
                            <input
                              type="text"
                              class="dexktop-control"
                              autocomplete="off"
                              name=""
                              placeholder="Describe other"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="check-boxes cb-box mb-3 acol-6">
                        <p class="mb-2">
                          <b>Incoterms </b>
                        </p>
                        <div class="Cby-checkbox">
                          <span class="checkbox">
                            <input
                              type="radio"
                              class="checkbox-in "
                              name="fco"
                              id="FOB"
                              checked
                            />
                            <label for="FOB" class="checkbox-label">
                              FOB
                            </label>
                          </span>
                          <span class="checkbox">
                            <input
                              type="radio"
                              class="checkbox-in "
                              name="fco"
                              id="CIF"
                            />
                            <label for="CIF" class="checkbox-label">
                              CIF
                            </label>
                          </span>
                          <span class="checkbox">
                            <input
                              type="radio"
                              class="checkbox-in"
                              name="fco"
                              id="Othera"
                            />
                            <label for="Othera" class="checkbox-label">
                              Other
                            </label>
                          </span>
                          <span class="checkbox input-box">
                            <input
                              type="text"
                              class="dexktop-control"
                              autocomplete="off"
                              name=""
                              placeholder="Describe other"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Price">Price</label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="Price"
                      name=""
                      class="dexktop-control"
                      placeholder="EX: Gross USD 300 perMT, Net USD per"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">Commission Paid by</label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="Commission"
                      name=""
                      class="dexktop-control"
                      placeholder="EX: Seller, Buyer, Seller & Buyer"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">Total of</label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="Total"
                      name=""
                      class="dexktop-control"
                      placeholder="EX: USD 10"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Contract">Description</label>
                    <textarea
                      name=""
                      id=""
                      class="dexktop-control"
                      rows="5"
                      placeholder="Describe your offer in detail with your company name, contact, email, product name(s)."
                    ></textarea>
                  </div>
                </div>

                <div class="col-lg-12 col-md-4 col-12">
                  <div class="input-box uplod-files">
                    <label for="Upload-File-a">Upload File(s)</label>
                    <p>
                      <small>
                        Ex: Company Registration copy, Passport Copy, Previous
                        Record(s), POP, POF, Draft contract
                      </small>
                    </p>
                    <p class="text-foa">
                      THIS IS FOR OUR CONFIRMATION PURPOSE ONLY. WE WILL NOT
                      SHARE YOUR FILE(S) WITHOUT YOUR WRITTEN CONSENT. YOUR
                      FILES WILL BE ENCRYPTED AND SECURELY STORED IN OFFLINE.
                      UPON YOUR REQUEST, WE WILL DESTORY YOUR FILES IMMEDIATELY.
                      More than 5 files, please send it direct to
                      verified@realcommoditytrading.com
                    </p>
                    <input
                      type="file"
                      autocomplete="off"
                      id="Upload-File-a"
                      name=""
                      class="dexktop-control"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <div class="check-boxes mb-3 acol-12">
                    <p>
                      <span class="checkbox">
                        <input
                          type="checkbox"
                          class="checkbox-in"
                          name="i_agree"
                          id="check-a"
                        />
                        <label for="check-a" class="checkbox-label">
                          I did check Service Fee Agreement
                        </label>
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row justify-content-center">
                  <div class="col-md-4">
                    <button
                      class="btn-theme btn-secondary full-width"
                      type="button"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default RequestVerifiedOfferPostCreation;
