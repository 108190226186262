const InquiryOthers = () => {
  return (
    <>
      <section class="login-section-3">
        <div class="container">
          <div class="forms rvop-form">
            <form action="">
              <div class="row">
                <div class="col-lg-6  col-12">
                  <div class="input-box acol-6">
                    <label for="Your Name">
                      Your Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="Your Name"
                      name=""
                      class="dexktop-control"
                      placeholder="Your Name"
                    />
                  </div>
                </div>
                <div class="col-lg-6  col-12">
                  <div class="input-box acol-6">
                    <label for="Email Address">
                      Email Address<span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      autocomplete="off"
                      id="Email Address"
                      name=""
                      class="dexktop-control"
                      placeholder="Email Address"
                    />
                  </div>
                </div>

                <div class="col-lg-12 col-md-4 col-12">
                  <div class="input-box acol-6">
                    <label for="Inquiry Description">Inquiry Description</label>
                    <textarea
                      name=""
                      id=""
                      class="dexktop-control"
                      rows="5"
                      placeholder="Please describe your inquiry as detail as possible.We will reply as soon as possible."
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row justify-content-center">
                  <div class="col-md-4">
                    <button
                      class="btn-theme btn-secondary full-width"
                      type="button"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default InquiryOthers;
