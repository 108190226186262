import React from "react";
import { Helmet } from "react-helmet";
import footerlogo from "../../assets/img/footerlogo.png";

const footer = () => {
  return (
    <>
      <footer id="footer" class="footer">
        <div class="footer-content ">
          <div class="container">
            <div class="footer-logo-link">
              <div class="footer-Linfo">
                <a href="javascript:void(0);">
                  <div class="">
                    <img class="img-fluid" src={footerlogo} alt="" />
                  </div>
                  <span class="text-white">
                    <strong>
                      {" "}
                      REAL <br />
                      COMMODITY <br />
                      TRADING{" "}
                    </strong>
                  </span>
                </a>
              </div>
              <div class="footer-info ">
                <h4>
                  <a class="" href="#">
                    Log In/Register
                  </a>
                </h4>
              </div>
            </div>
            <div class="main-box-fo">
              <div class="min-row">
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#"> HOME</a>
                    </h4>
                  </div>
                </div>
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#"> ABOUT US</a>
                    </h4>
                  </div>
                </div>
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#">PETROLEUM</a>
                    </h4>
                    <ul class="list-unstyled">
                      <li>
                        <a class="" href="#">
                          {" "}
                          Crude Oil
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          EN 590
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          LCO
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          LNG / LPG
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          A1 / JP54
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Others P
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#">METAL</a>
                    </h4>
                    <ul class="list-unstyled">
                      <li>
                        <a class="" href="#">
                          {" "}
                          Copper
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Aluminum
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Gold
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Used Rail
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Iron Ore
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Others M
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#">ARGI/MEAT</a>
                    </h4>
                    <ul class="list-unstyled">
                      <li>
                        <a class="" href="#">
                          {" "}
                          Soybean
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Sugar
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Seed Oils
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Beef
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Chicken
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Pork
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Lamb
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Others AM
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="min-row">
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#"> FINANCE</a>
                    </h4>
                    <ul class="list-unstyled">
                      <li>
                        <a class="" href="#">
                          {" "}
                          Project Finance
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Bank Instrument
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          Bit Coin
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Others F
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#"> SERVICE</a>
                    </h4>
                    <ul class="list-unstyled">
                      <li>
                        <a class="" href="#">
                          {" "}
                          Escrow Lawyer
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Inspection
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          Storage
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Banking
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Others S
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#">OTHER</a>
                    </h4>
                    <ul class="list-unstyled">
                      <li>
                        <a class="" href="#">
                          {" "}
                          Property / Art / Rare Items
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Others O
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#">Inquiry</a>
                    </h4>
                    <ul class="list-unstyled">
                      <li>
                        <a class="" href="#">
                          {" "}
                          Verified Offers
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Website Issues
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Partner Program
                        </a>
                      </li>
                      <li>
                        <a class="" href="#">
                          {" "}
                          Others
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="footer-box2">
                  <div class="fob-content">
                    <h4>
                      <a href="#">News</a>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="min-row">
                <div class="footer-box3">
                  <h4>
                    <a href="#">DOCUMENTS</a>
                  </h4>
                  <ul class="nav new-footer-bar">
                    <li class=" ">
                      <a href="#" class="text-white">
                        Doc1{" "}
                      </a>
                    </li>
                    <li class=" ">
                      <a href="#" class="text-white">
                        Doc2{" "}
                      </a>
                    </li>
                    <li class=" ">
                      <a href="#" class="text-white">
                        Doc3{" "}
                      </a>
                    </li>
                    <li class=" ">
                      <a href="#" class="text-white">
                        Doc4{" "}
                      </a>
                    </li>
                    <li class="">
                      <a href="#" class="text-white">
                        Doc5{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-legal">
          <div class="container">
            <div class="copyright-box">
              <div class="copyright">
                Copyright © realcommoditytrading.com 2022{" "}
              </div>
              <ul class="">
                <li class="">
                  <a href="#" class="text-white">
                    Terms Of Use{" "}
                  </a>
                </li>
                <li class="">
                  <a href="#" class="text-white">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
