import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const navigate = useNavigate();

  const navigateTo = (e) => {
    navigate("/login");
    e.preventDefault();
  };

  return (
    <>
      <section class="login-section-3">
        <div class="forms login">
          <form>
            <h3 class="text-center top-name">Password Recovery</h3>
            <p class="text-center mt-3 pb-3 text-dfo">
              Please enter your Email address to reset password.
            </p>

            <div class="input-box">
              <input
                type="email"
                autocomplete="off"
                id="email"
                idd="fixss1"
                placeholder=" Enter your registered email"
                name="email"
                class="dexktop-control"
              />
            </div>

            <button class="btn-theme btn-secondary full-width" type="button">
              Send Password Reset Link
            </button>

            <p class="text-center mt-3 text-dfo">
              Know your password?<a onClick={navigateTo}> Login</a>
            </p>

            <div class="other-links">
              {/* <!-- <span class="orbox"><span>OR</span> </span> -->
                        <!-- <div class="sociallinks">
                            <ul>
                                <li><a href="javascript:void(0);"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                <li><a href="javascript:void(0);"><i class="fa fa-google" aria-hidden="true"></i></a></li>
                                <li><a href="javascript:void(0);"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                            </ul>
                        </div> --> */}
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
export default ForgotPassword;
